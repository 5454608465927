import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Lock: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 33 33" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40868 14.2237H7.67633C6.17868 14.2237 4.9646 15.4377 4.9646 16.9354V28.867C4.9646 30.3646 6.17868 31.5787 7.67633 31.5787H25.3235C26.8212 31.5787 28.0352 30.3646 28.0352 28.867V16.9354C28.0352 15.4377 26.8212 14.2237 25.3235 14.2237H23.5912V9.7448C23.5912 5.82842 20.4163 2.65356 16.4999 2.65356C12.5835 2.65356 9.40868 5.82841 9.40868 9.74479V14.2237ZM20.8794 14.2237V9.7448C20.8794 7.32607 18.9186 5.36529 16.4999 5.36529C14.0812 5.36529 12.1204 7.32606 12.1204 9.74479V14.2237H20.8794ZM17.5348 22.8956C18.4927 22.4718 19.1611 21.5131 19.1611 20.3983C19.1611 18.8909 17.9392 17.6689 16.4318 17.6689C14.9244 17.6689 13.7025 18.8909 13.7025 20.3983C13.7025 21.5132 14.371 22.472 15.3291 22.8957V27.0307C15.3291 27.6398 15.8229 28.1335 16.4319 28.1335C17.041 28.1335 17.5348 27.6398 17.5348 27.0307V22.8956Z"
      />
    </Svg>
  )
}

export default Lock
