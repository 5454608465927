import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 219.781 219.781" {...props}>
      <path
        d="M202.314,154.591l-22.323-12.888l19.345-11.407c3.568-2.104,4.755-6.702,2.651-10.27c-2.104-3.567-6.701-4.754-10.27-2.651
        l-26.648,15.713l-40.179-23.198l40.172-23.194l26.656,15.711c1.195,0.705,2.507,1.039,3.801,1.039c2.568,0,5.069-1.32,6.468-3.693
        c2.103-3.568,0.915-8.166-2.653-10.27L179.986,78.08l22.328-12.891c3.587-2.071,4.816-6.658,2.745-10.245
        c-2.071-3.588-6.658-4.817-10.245-2.745l-22.323,12.889l-0.203-22.461c-0.037-4.142-3.435-7.489-7.567-7.432
        c-4.142,0.037-7.47,3.425-7.432,7.567l0.279,30.941L117.391,96.9V50.51l26.937-15.224c3.606-2.038,4.877-6.613,2.839-10.22
        c-2.038-3.606-6.614-4.878-10.22-2.839L117.391,33.28V7.5c0-4.142-3.358-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v25.78
        L82.835,22.228c-3.608-2.039-8.181-0.768-10.22,2.839c-2.038,3.606-0.767,8.182,2.839,10.22l26.936,15.224V96.9L62.213,73.703
        l0.279-30.941c0.038-4.142-3.29-7.53-7.432-7.567c-0.023,0-0.045,0-0.069,0c-4.11,0-7.461,3.313-7.498,7.433L47.29,65.088
        L24.967,52.199c-3.587-2.072-8.174-0.842-10.245,2.745c-2.071,3.587-0.842,8.174,2.745,10.245L39.794,78.08L20.443,89.485
        c-3.568,2.103-4.756,6.701-2.653,10.27c1.399,2.373,3.9,3.693,6.469,3.693c1.294,0,2.605-0.335,3.801-1.039l26.659-15.711
        l40.173,23.194l-40.18,23.198L28.06,117.374c-3.566-2.103-8.166-0.917-10.27,2.651c-2.104,3.568-0.917,8.166,2.651,10.269
        l19.348,11.408l-22.323,12.888c-3.587,2.071-4.816,6.658-2.745,10.245c1.389,2.406,3.91,3.751,6.502,3.751
        c1.272,0,2.562-0.324,3.743-1.006l22.323-12.889l0.203,22.462c0.037,4.119,3.388,7.433,7.498,7.433c0.023,0,0.046,0,0.069-0.001
        c4.142-0.037,7.47-3.425,7.432-7.567l-0.279-30.942l40.178-23.197v46.391l-26.936,15.224c-3.606,2.038-4.877,6.613-2.839,10.22
        c1.379,2.44,3.92,3.811,6.536,3.811c1.25,0,2.518-0.313,3.684-0.972l19.556-11.052v25.78c0,4.142,3.358,7.5,7.5,7.5
        c4.142,0,7.5-3.358,7.5-7.5v-25.78l19.556,11.052c1.166,0.659,2.433,0.972,3.684,0.972c2.616,0,5.157-1.371,6.536-3.811
        c2.038-3.606,0.767-8.182-2.839-10.22l-26.937-15.224V122.88l40.177,23.196l-0.286,30.941c-0.039,4.142,3.288,7.531,7.43,7.569
        c0.024,0.001,0.047,0.001,0.071,0.001c4.109,0,7.46-3.313,7.498-7.431l0.208-22.465l22.326,12.89
        c1.181,0.682,2.471,1.006,3.743,1.006c2.592,0,5.113-1.346,6.502-3.751C207.131,161.249,205.902,156.662,202.314,154.591z"
      />
    </Svg>
  )
}

export default Icon
