import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 452.000000 452.000000" {...props}>
      <g transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M917 3960 c-178 -30 -323 -184 -347 -370 -5 -41 -10 -266 -10 -500
            l-1 -425 -67 -45 c-152 -102 -316 -297 -393 -466 -150 -336 -124 -735 69
            -1047 157 -254 396 -430 690 -509 99 -26 339 -36 450 -18 341 54 645 273 813
            584 23 43 55 115 70 160 l28 81 308 3 308 2 -3 -119 c-3 -98 0 -133 18 -197
            68 -249 255 -436 505 -505 67 -18 97 -21 204 -17 105 4 138 10 201 32 206 76
            367 236 436 435 13 38 29 107 35 152 28 209 -58 441 -220 592 l-51 48 0 272
            c0 150 -5 306 -10 347 -19 144 -107 268 -239 333 l-65 32 -269 3 -269 3 4 187
            c3 170 5 191 25 228 43 80 155 154 235 154 17 0 18 11 18 140 l0 140 -38 0
            c-168 0 -381 -140 -461 -301 -51 -104 -61 -157 -68 -362 l-6 -187 -231 2 -231
            3 -181 445 c-100 245 -195 467 -210 494 -58 98 -169 173 -288 195 -69 13 -684
            13 -759 1z m760 -300 c18 -10 40 -34 49 -52 9 -17 106 -250 215 -516 109 -267
            208 -495 220 -508 12 -13 39 -28 58 -34 22 -6 290 -10 696 -10 637 0 661 -1
            692 -20 60 -36 63 -53 63 -318 l0 -238 -66 9 c-79 11 -203 -2 -295 -33 -96
            -32 -195 -95 -277 -177 l-73 -73 -348 0 -348 0 -7 90 c-13 171 -73 357 -164
            503 -158 256 -394 429 -690 509 -109 29 -336 36 -453 14 -47 -9 -89 -16 -92
            -16 -4 0 -7 178 -7 395 0 437 0 437 63 475 30 19 52 20 382 20 330 0 352 -1
            382 -20z m-385 -1134 c320 -62 573 -300 659 -621 30 -113 30 -307 0 -420 -101
            -380 -432 -635 -821 -635 -389 0 -720 256 -821 635 -30 113 -30 307 0 420 24
            90 74 202 121 270 119 172 326 309 529 350 89 18 243 18 333 1z m2420 -873
            c88 -41 160 -113 201 -201 30 -63 32 -73 32 -182 0 -110 -2 -119 -32 -180 -78
            -160 -229 -247 -409 -238 -70 4 -94 10 -157 41 -141 70 -227 199 -235 352 -7
            132 31 234 120 324 91 92 171 122 313 118 88 -2 108 -6 167 -34z"
        />
        <path
          d="M1043 1962 c-122 -44 -193 -141 -193 -266 0 -158 123 -281 280 -281
            195 0 328 190 264 377 -46 137 -215 219 -351 170z"
        />
      </g>
    </Svg>
  )
}

export default Icon
