import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 399" {...props}>
      <path d="M433.43 33.0104C400.796 17.7313 365.802 6.47438 329.214 0.0270457C328.547 -0.0973858 327.882 0.213575 327.539 0.835512C323.038 9.00348 318.053 19.6593 314.562 28.0347C275.209 22.0228 236.057 22.0228 197.511 28.0347C194.019 19.4731 188.853 9.00348 184.332 0.835512C183.989 0.234326 183.323 -0.0766349 182.657 0.0270457C146.089 6.45378 111.095 17.7107 78.4413 33.0104C78.1586 33.1348 77.9163 33.3423 77.7555 33.6116C11.379 134.801 -6.80427 233.503 2.11584 330.981C2.1562 331.458 2.41856 331.914 2.78182 332.204C46.5751 365.021 88.9964 384.944 130.63 398.15C131.296 398.357 132.002 398.108 132.426 397.548C142.275 383.825 151.054 369.355 158.581 354.137C159.025 353.246 158.601 352.189 157.693 351.836C143.768 346.446 130.509 339.874 117.754 332.411C116.745 331.81 116.665 330.338 117.593 329.633C120.277 327.581 122.961 325.445 125.524 323.289C125.988 322.895 126.634 322.812 127.179 323.061C210.971 362.098 301.686 362.098 384.49 323.061C385.035 322.792 385.681 322.875 386.165 323.268C388.728 325.425 391.412 327.581 394.117 329.633C395.045 330.338 394.984 331.81 393.975 332.411C381.221 340.019 367.961 346.446 354.016 351.816C353.108 352.168 352.705 353.246 353.149 354.137C360.837 369.333 369.616 383.804 379.283 397.528C379.687 398.108 380.413 398.357 381.079 398.15C422.915 384.944 465.336 365.021 509.129 332.204C509.513 331.914 509.755 331.478 509.795 331.001C520.471 218.306 491.914 120.413 434.095 33.6322C433.954 33.3423 433.712 33.1348 433.43 33.0104ZM171.094 271.627C145.867 271.627 125.08 247.994 125.08 218.97C125.08 189.946 145.464 166.313 171.094 166.313C196.925 166.313 217.511 190.153 217.107 218.97C217.107 247.994 196.723 271.627 171.094 271.627ZM341.221 271.627C315.995 271.627 295.208 247.994 295.208 218.97C295.208 189.946 315.591 166.313 341.221 166.313C367.054 166.313 387.638 190.153 387.235 218.97C387.235 247.994 367.054 271.627 341.221 271.627Z" />
    </Svg>
  )
}

export default Icon
