import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 378.000000 378.000000" {...props}>
      <g transform="translate(0.000000,378.000000) scale(0.100000,-0.100000)">
        <path
          d="M983 3497 c-84 -45 -152 -86 -153 -92 0 -13 338 -385 351 -385 15 0
          129 60 129 68 0 14 -165 492 -170 491 -3 0 -73 -37 -157 -82z"
        />
        <path
          d="M1375 3531 c-91 -22 -109 -30 -107 -44 2 -10 23 -70 48 -135 25 -64
          55 -140 65 -168 l20 -52 49 16 50 16 0 198 c0 109 -3 198 -7 197 -5 -1 -57
          -13 -118 -28z"
        />
        <path
          d="M2495 3384 c-63 -52 -239 -165 -319 -204 -114 -55 -213 -77 -397 -89
          -181 -11 -255 -32 -407 -111 -170 -89 -295 -184 -393 -295 -90 -103 -146 -136
          -326 -190 -198 -61 -265 -43 -421 113 -52 51 -97 91 -100 87 -3 -3 -6 -49 -5
          -103 0 -109 25 -219 75 -340 72 -174 222 -261 433 -250 l77 3 -7 -60 c-17
          -159 -122 -378 -281 -586 -24 -31 -40 -59 -37 -63 13 -13 80 -5 118 14 21 11
          40 18 42 16 2 -2 -12 -88 -33 -192 -28 -144 -35 -202 -30 -244 23 -200 265
          -402 692 -580 126 -52 218 -76 351 -91 233 -26 534 34 811 162 47 21 86 39 87
          39 1 0 5 -21 9 -48 6 -43 58 -172 70 -172 3 0 13 14 22 31 24 47 156 181 390
          395 248 226 286 267 316 343 20 48 23 73 22 186 0 96 -5 141 -17 173 l-16 42
          85 0 c47 0 84 3 82 8 -2 4 -22 43 -44 87 -84 163 -266 577 -258 584 5 4 32 16
          59 26 89 32 212 94 288 145 93 62 161 135 195 208 25 52 27 68 27 177 l0 120
          -40 79 c-22 44 -45 81 -51 83 -7 2 -23 -16 -37 -40 -50 -85 -136 -148 -246
          -181 -61 -18 -221 -22 -221 -6 0 6 4 10 8 10 10 0 112 124 112 136 0 5 -33 10
          -73 10 -71 1 -293 46 -309 62 -5 5 -2 25 7 45 18 43 45 166 45 204 0 22 -3 24
          -22 19 -52 -15 -168 -35 -240 -42 l-77 -7 15 48 c15 50 20 265 7 265 -5 -1
          -21 -12 -38 -26z m-503 -815 c28 -15 47 -66 54 -148 4 -39 10 -71 15 -71 20 0
          115 88 142 131 l30 49 57 -85 c31 -47 67 -108 80 -135 13 -28 27 -50 32 -50
          15 0 60 53 78 93 l17 38 31 -35 c45 -52 77 -111 108 -201 26 -75 28 -91 28
          -245 1 -179 -12 -400 -22 -400 -4 1 -25 37 -47 82 -22 45 -45 83 -52 85 -6 2
          -20 -25 -33 -64 -12 -37 -38 -100 -56 -140 l-34 -72 -70 -21 c-205 -61 -396
          -84 -575 -70 -202 17 -236 25 -267 62 -44 51 -119 209 -125 261 -6 53 -13 56
          -54 26 -46 -32 -88 -103 -108 -179 l-17 -69 -33 54 c-83 137 -121 280 -121
          454 1 190 24 297 82 371 55 70 197 135 472 215 231 66 347 86 388 64z"
        />
        <path
          d="M1336 2151 c-3 -4 -12 -35 -21 -67 -52 -200 46 -330 263 -350 93 -9
          169 14 226 66 44 41 82 109 73 132 -4 11 -509 228 -529 228 -4 0 -9 -4 -12 -9z"
        />
        <path
          d="M2245 2023 c-132 -41 -241 -77 -243 -78 -9 -8 51 -105 85 -141 98
          -101 223 -99 336 5 63 58 89 123 85 213 -2 39 -7 73 -13 74 -5 2 -118 -31
          -250 -73z"
        />
        <path
          d="M747 3118 c-42 -51 -73 -96 -69 -100 4 -4 77 -34 162 -66 l155 -60
          45 36 c25 19 49 39 53 42 9 8 -246 240 -262 240 -5 0 -43 -42 -84 -92z"
        />
      </g>
    </Svg>
  )
}

export default Icon
