export default {
  "summitToken": {
    "97": "0xE7d16d8D57afE3455746d960570B6Da7Dd89759e",
    "137": "0x16abABE2C69e29a847A7Cb9DcA01866c9ceFB9bf",
    "250": "0x0dDB88e14494546D07fCd94c3f0ef6D3296B1cD7"
  },
  "everestToken": {
    "97": "0x83Db6A464E88FF9bD77d0737847F2d6B43239346",
    "137": "0x1243f859D73CCDcF0E81C8421Ae94F3e9F777B6C",
    "250": "0xC687806Cfd11B5330d7c3aE6f18B18DC71e1083e"
  },
  "summitLpToken": {
    "137": "0x082Dc7e87eDC2e5868Cf05d305e923Bfd8984341",
    "250": "0x06300c5e8e55cC40Bf3A0383F69aCf18f1d32EC1"
  },
  "cartographer": {
    "97": "0xf5205fc9D7B9c22CeC5765ec5F55535aD23b3D39",
    "137": "0x8733820E0097e9289c30764018B9eE5d47E8332B",
    "250": "0x71210E72D065C19406913cD706e964A9f21856D4",
    "31337": "0x5FbDB2315678afecb367f032d93F642f64180aa3"
  },
  "cartographerOasis": {
    "97": "0x69fC1473904037DD8CC064DcC20D1fB679933c01",
    "137": "0x150E7E68C4b8D021b39f53c7f1E36f74CFeb6A10",
    "250": "0x8047C5Bed363FE1bf458eC3E20E93A3c28A07b8d",
    "31337": "0x4ed7c70F96B99c776995fB64377f0d4aB3B0e1C1"
  },
  "cartographerPlains": {
    "97": "0x7f2C78F9C557166acaac15C4D9F03208D3F58947",
    "137": "0xe4018320a9CFF529f7667A35eec28C3e0d9f3CB8",
    "250": "0x1805922e7F82fc9DbAd8E2435C146ba605C4a25d"
  },
  "cartographerMesa": {
    "97": "0x03386C2806c7c36797E9931ad703182aa2fba8EC",
    "137": "0xE47C011D7d3b44b793f08563c966E9de38F5102c",
    "250": "0x64F8a1DBC20f132159605Ad8d7111e75EA702358"
  },
  "cartographerSummit": {
    "97": "0x50F371a953adD1855cbD5CCbff9aFBb8bce4565A",
    "137": "0x17325EE35A86d714be6CAe066eFf005db1cED542",
    "250": "0x93af6a3882aAF4112Fc404E30277b39452F44cf6"
  },
  "expedition": {
    "97": "0x90c8240af56411F2678B3DB84AD41cd8Dd68F325",
    "137": "0x3F6dd853A2ac34294Bf10047ed12F795ab8Aee06",
    "250": "0x94233b479B37FBb41E81C63E27b6C2279646C609"
  },
  "summitGlacier": {
    "97": "0xdcDe21FcBbfcc3D31eb3C75A3edCEba45F530407",
    "137": "0x845571c03E7122fAE19aF04Cf4d3868F2b103926",
    "250": "0x17EB377C16653523DFAa8402de8A8eEe4832c108"
  },
  "elevationHelper": {
    "97": "0x864aFec9dF77F89b24B593a0E0Bc1e63FD7fd53C",
    "137": "0x615255f0459B09a5404f316e8a549dd17eD11929",
    "250": "0xDfBb673787DfC1477b0ca7890887136a13296811",
    "31337": "0x4A679253410272dd5232B3Ff7cF5dbB88f295319"
  },
  "summitTrustedSeederModule": {
    "97": "0xDfBb673787DfC1477b0ca7890887136a13296811",
    "137": "0x05A6e85EA60e92103d68dff4257994e99D4dc3F0",
    "250": "0xfccf00ccdeb8964c0f996f332f5a0f763d571d27"
  },
  "summitReferrals": {
    "97": "0x4F42CEa42847Fa8BF33EBf1E5be07081dC39a69f",
    "250": "0x0B90dd88692Ec4fd4A77584713E3770057272B38",
    "31337": "0x7a2088a1bFc9d81c55368AE168C2C02570cB814F"
  },
  "multicall": {
    "56": "0x1ee38d535d541c55c9dae27b12edf090c608e6fb",
    "97": "0x67ADCB4dF3931b0C5Da724058ADC2174a9844412",
    "137": "0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507",
    "250": "0xb828c456600857abd4ed6c32facc607bd0464f4f",
  },
  "ammFactory": {
    "56": "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    "97": "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
    "250": "0x152eE697f2E276fA89E96742e9bB9aB1F2E61bE3"
  },
  "ammRouter": {
    "56": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    "97": "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    "250": "0xF491e7B69E4244ad4002BC14e878a34207E38c29"
  },
  "wrappedNativeToken": {
    "56": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    "97": "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    "250": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    "137": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
  },
  "expeditionTreasury": {
    "250": "0x00676eF184C36EBf73d0F3059D2a6909F02AA893",
    "137": "0x00676eF184C36EBf73d0F3059D2a6909F02AA893"
  },
  "beethoven2PoolOracle": {
    "250": "0x33365E1B22BbeF5766419e19f77c15fD3E0a8Ae5"
  },
  "beethovenMultiPoolOracle": {
    "97": "0xdDE494d67e6d343AC1460135df84b7BCa9448B54",
    '250': '0x20dd72Ed959b6147912C2e529F0a0C651c33c9ce'
  },
  "peggedToken": {
    "56": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "97": "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
    "250": "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
    "137": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"
  },
  "nativePeggedLp": {
    "56": "",
    "97": "",
    "250": "0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c",
    "137":  "0xA374094527e1673A86dE625aa59517c5dE346d32",
  },
  "timelock": {
    "97": "0x871Df25A2c6050eb067B7C8C0E0F6bD6af1147e9",
    "137": "0xAC82350881bCE8C478661A874d0B9164461b52Ec",
    "250": "0x191528B779Ada279145D42350226bF75c0c73715"
  },
  "oldSummitToken": {
    "97": "0x5887fa698320693A0D610c597658FAD5D6BF5009",
    "250": "0x8F9bCCB6Dd999148Da1808aC290F2274b13D7994"
  }
}