import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 509" {...props}>
      <path d="M373 323C373 310.297 383.297 300 396 300C408.703 300 419 310.297 419 323C419 335.703 408.703 346 396 346C383.297 346 373 335.703 373 323Z"       />
      <path fillRule="evenodd" clipRule="evenodd" d="M444.797 136.458L380.62 25.3013C366.813 1.38668 336.234 -6.80707 312.319 7.00005L88.8846 136H50C22.3858 136 0 158.386 0 186V459C0 486.614 22.3858 509 50 509H438C465.614 509 488 486.614 488 459V390.672C502.127 384.499 512 370.403 512 354V292C512 275.597 502.127 261.501 488 255.328V186C488 160.691 469.196 139.774 444.797 136.458ZM134.885 136H183.446L358.958 34.1488C350.89 23.3126 335.805 19.9988 323.819 26.9186L134.885 136ZM276.168 136H229.271L370.617 53.9751L382.008 73.704L276.168 136ZM321.511 136H417.974L393.508 93.6233L321.511 136ZM50 159H438C452.912 159 465 171.088 465 186V252H397C357.788 252 326 283.788 326 323C326 362.212 357.788 394 397 394H465V459C465 473.912 452.912 486 438 486H50C35.0883 486 23 473.912 23 459V186C23 171.088 35.0883 159 50 159ZM397 275H472C481.389 275 489 282.611 489 292V354C489 363.389 481.389 371 472 371H397C370.49 371 349 349.51 349 323C349 296.49 370.49 275 397 275Z"        />
    </Svg>
  )
}

export default Icon
