import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24pt" height="18pt" viewBox="0 0 737.093567 739.186422" preserveAspectRatio="xMidYMid meet" {...props}>
      <g transform="translate(-100,819.674550) scale(0.100000,-0.100000)">
        <path d="M4365 8194 c-512 -41 -939 -154 -1365 -360 -395 -192 -713 -417 -1015 -719 -592 -592 -958 -1349 -1062 -2195 -24 -202 -24 -638 0 -840 104 -846 470 -1603 1062 -2195 592 -592 1349 -958 2195 -1062 202 -24 638 -24 840 0 846 104 1603 470 2195 1062 520 519 875 1184 1014 1898 52 264 65 412 65 717 0 305 -13 453 -65 717 -139 714 -494 1379 -1014 1898 -589 589 -1353 960 -2185 1060 -129 16 -556 28 -665 19z m452 -379 c630 -27 1321 -295 1854 -716 134 -105 422 -394 529 -529 504 -636 754 -1413 719 -2232 -30 -682 -286 -1362 -719 -1908 -107 -135 -395 -423 -530 -530 -321 -255 -736 -472 -1115 -584 -718 -212 -1483 -180 -2170 91 -639 252 -1183 695 -1565 1275 -536 812 -684 1827 -404 2773 110 370 324 783 568 1095 88 113 247 283 376 402 414 384 953 666 1515 792 233 53 582 88 770 79 33 -1 111 -5 172 -8z" />
        <path d="M4627 7662 c-44 -46 -38 -120 23 -300 174 -515 258 -991 230 -1295 -29 -309 -129 -562 -319 -807 -145 -188 -291 -317 -776 -685 -247 -187 -310 -239 -403 -329 -332 -322 -465 -633 -449 -1046 13 -336 118 -587 342 -819 155 -161 276 -246 465 -328 101 -44 126 -52 342 -108 245 -64 454 -142 531 -199 35 -26 67 -76 67 -106 0 -12 -12 -45 -26 -74 -18 -34 -28 -73 -32 -119 -5 -67 -4 -69 29 -103 29 -30 39 -34 83 -34 72 0 128 32 232 132 107 103 139 161 156 278 9 62 9 99 -1 167 -6 47 -16 94 -21 103 -8 15 -2 18 46 24 287 34 689 403 848 779 56 131 76 243 76 423 0 233 -40 357 -164 512 -112 138 -250 246 -389 304 -195 83 -471 90 -626 17 -113 -52 -281 -207 -362 -332 -62 -96 -79 -153 -79 -267 0 -97 1 -100 31 -135 30 -34 33 -35 104 -35 l73 0 49 57 c65 77 88 97 153 129 75 37 131 37 207 -2 49 -25 62 -38 86 -84 23 -46 27 -64 24 -111 -12 -146 -145 -270 -377 -351 -89 -31 -103 -33 -225 -32 -113 0 -142 4 -219 28 -158 48 -329 146 -408 234 -77 85 -116 195 -105 293 19 160 164 359 422 581 148 127 398 387 499 518 274 357 459 771 507 1134 18 142 6 592 -20 737 -52 295 -141 590 -264 876 -68 157 -159 328 -195 366 -32 33 -41 37 -87 37 -43 0 -56 -4 -78 -28z" />
        <path d="M4457 6862 l-27 -28 1 -219 c1 -164 -3 -243 -15 -310 -70 -383 -200 -663 -501 -1075 -83 -113 -95 -139 -95 -203 0 -45 4 -57 28 -80 24 -23 37 -27 83 -27 76 0 123 32 292 199 272 268 417 509 499 831 25 101 30 140 35 310 5 162 3 212 -12 295 -27 153 -60 250 -102 296 -34 37 -38 39 -97 39 -55 0 -65 -3 -89 -28z" />
      </g>
    </Svg>
  )
}

export default Icon
